document.addEventListener('DOMContentLoaded', function() {
    var meetUsElement = document.querySelector('.count-box');
    var hasStartedCounting = false;

    function isElementInViewport(element) {
        var elementTop = element.offsetTop;
        var elementBottom = elementTop + element.offsetHeight;
        var viewportTop = window.pageYOffset || document.documentElement.scrollTop;
        var viewportBottom = viewportTop + window.innerHeight;
        return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    function startCounting() {
        if (isElementInViewport(meetUsElement) && !hasStartedCounting) {
            hasStartedCounting = true;
            var countElements = document.querySelectorAll('.count');
            countElements.forEach(function(element) {
                var textValue = element.textContent.trim();
                if (!isNaN(parseFloat(textValue)) && !textValue.includes('.')) {
                    var targetValue = parseFloat(textValue);
                    var start = 0;
                    var duration = 2000;
                    var step = function(now) {
                        element.textContent = Math.ceil(now);
                    };
                    animateValue(start, targetValue, duration, step);
                } else if (textValue.includes('.') && !isNaN(parseFloat(textValue))) {
                    var targetValue = parseFloat(textValue);
                    var start = 0;
                    var duration = 2000;
                    var step = function(now) {
                        element.textContent = now.toFixed(1);
                    };
                    animateValue(start, targetValue, duration, step);
                }
            });
        }
    }

    function animateValue(start, end, duration, step) {
        var range = end - start;
        var current = start;
        var increment = end > start ? 1 : -1;
        var startTime = new Date();
        function animate() {
            var now = new Date();
            var elapsed = now - startTime;
            current = start + increment * Math.min(elapsed / duration, 1) * range;
            step(current);
            if (elapsed < duration) {
                requestAnimationFrame(animate);
            }
        }
        animate();
    }

    window.addEventListener('scroll', function() {
        startCounting();
    });

    startCounting();
});
