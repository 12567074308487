function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    var windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    return (
      rect.top >= 0 &&
      rect.bottom <= (windowHeight / 2)
    );
  }

  function activateTOC() {
    var faqContent = document.querySelector('.content__box');
    var headings = faqContent.querySelectorAll('h3');
    var tocItems = document.querySelectorAll('.toc__list-item');

    headings.forEach(function (heading, index) {
      if (isElementInViewport(heading)) {
        tocItems.forEach(function (tocItem) {
          tocItem.classList.remove('toc__list-item--active');
        });
        tocItems[index].classList.add('toc__list-item--active');
      }
    });
  }

  if (window.innerWidth >= 1200) {
    window.addEventListener('scroll', activateTOC);
    activateTOC();
  }
