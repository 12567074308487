document.addEventListener("DOMContentLoaded", function() {
   const scrollButtons = document.querySelectorAll(".scroll-to-section");

   scrollButtons.forEach(function(button) {
       button.addEventListener("click", function(event) {
           event.preventDefault();

           const targetId = this.getAttribute("href").substring(1);
           const targetElement = document.getElementById(targetId);
           const targetOffset = targetElement.offsetTop;

           window.scrollTo({
               top: targetOffset,
               behavior: "smooth"
           });
       });
   });
});
